(function () {
	'use strict';

	angular
		.module('smartermail')
		.controller('directoryServiceProviderController', directoryServiceProviderController);

	function directoryServiceProviderController($scope, $http, $mdDialog, errorHandling, existingProvider) {
		/* jshint validthis:true */
		var vm = this;
		vm.title = 'directory-Service-Provider';
		vm.saveCredentials = true;
		vm.isNew = existingProvider === undefined;

		// functions
		vm.cancel = cancel;
		vm.delete = deleteProvider;
		vm.onEncryptionChanged = onEncryptionChanged;
		vm.validateCredentialsAndSave = validateCredentialsAndSave;
		
		if (!vm.isNew && existingProvider) {
			// Both
			vm.providerType = existingProvider.providerType;
			vm.providerName = existingProvider.providerName;
			vm.serverName = existingProvider.serverName;
			vm.serverPort = existingProvider.serverPort;
			vm.encryption = existingProvider.encryption;

			//LDAP
			vm.ldapBaseDn = existingProvider.ldapBaseDn;
			vm.ldapUserName = existingProvider.ldapUsername;
			vm.ldapPassword = existingProvider.ldapPassword;
			vm.saveCredentials = existingProvider.saveCredentials;

			// AD
			vm.fqdn = existingProvider.fqdn;
			vm.organizationalUnit = existingProvider.organizationalUnit;
		} else {
			vm.providerType = 1;
			vm.encryption = 0;
			vm.serverPort = 389;
		}
		vm.activeUsers = [];
		activate();

		////////////////

		function activate() {
			if (!vm.isNew) {
				$http.get(`~/api/v1/settings/domain/auth-provider-user-list/${existingProvider.providerId}`)
					.then(function(success) {
							vm.activeUsers = success.data.usernames;
						},
						errorHandling.report);
			}
		}

		function cancel(ev) {
			$mdDialog.cancel();
		}
		
		function deleteProvider() {
			if (vm.activeProviders.length)
				return;
			$mdDialog.hide({ delete: true });
		}

		function onEncryptionChanged() {
			switch (vm.encryption) {
				case 0:
					if (vm.serverPort === 636)
						vm.serverPort = 389;
					break;
				case 1:
					if (vm.serverPort === 389)
						vm.serverPort = 636;
					break;
			}
		}
		
		function validateCredentialsAndSave(ev) {
			if ($scope.pageForm && $scope.pageForm.$dirty) {
				const params = {
					// Both
					providerType: vm.providerType,
					providerName: vm.providerName,
					serverName: vm.serverName,
					serverPort: vm.serverPort,
					encryption: vm.encryption,

					//LDAP
					ldapBaseDn: vm.ldapBaseDn,
					ldapUsername: vm.ldapUserName,
					ldapPassword: vm.ldapPassword,
					saveCredentials: vm.saveCredentials,

					// AD
					fqdn: vm.fqdn,
					organizationalUnit: vm.organizationalUnit
				};

				$http.post("~/api/v1/settings/domain/auth-provider-validate", JSON.stringify(params))
					.then(
						function (success) {
							$mdDialog.hide(params);
						},
						function (error) {
							vm.errorMessage = error.data.message;
						});
			} else {
				cancel();
			}
		}
	}
})();
