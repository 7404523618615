(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("onlyOfficeController", onlyOfficeController);

    function onlyOfficeController($timeout, $scope, $translate, $q, meetingWorkspaces, coreDataCalendar, coreDataFileStorage, errorHandling,
        userTimeService, coreData, coreDataSettings, claimsService, themesService, $http, localeInfoService, type, $mdDialog) {
        var vm = this;
        vm.type = type; // Capture the passed 'type' from locals
        vm.allFolders = [];

        // Data
        vm.availableDocumentTypes = [
            { id: 0, desc: $translate.instant("DOCUMENT") },
            { id: 1, desc: $translate.instant("DOCUMENT_PRESENTATION") },
            { id: 2, desc: $translate.instant("DOCUMENT_SPREADSHEET") }
        ];

        vm.details = {
            id: null,
            title: null,
            availableDocumentTypes: vm.type,  // Initialize with passed 'type'
            selectedFolder: null
        };

        vm.isValid = true;

        // Functions
        vm.cancel = cancel;
        vm.save = save;
        vm.getFolders = getFolders;

        activate();

        // Fetch folders
        function getFolders() {
            $http.get("~/api/v1/filestorage/folders")
                .then(function (response) {
                    vm.allFolders = getAllFolders(response.data.folder.subFolders);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        function getAllFolders(folders) {
            var allFolders = [];
            folders.forEach(function (folder) {
                allFolders.push(folder);
                if (folder.subFolders && folder.subFolders.length > 0) {
                    var subFolders = getAllFolders(folder.subFolders);
                    allFolders = allFolders.concat(subFolders);
                }
            });
            if (allFolders.length === 0) {
                allFolders.push({ path: '/' });
            }
            return allFolders;
        }

        function activate() {
            getFolders();
            // Any initialization can be placed here
        }

        function save() {
            var language = localeInfoService.language;
            vm.isValid = true;

            if (!vm.details.title) {
                vm.isValid = false;
            }

            if (vm.isValid) {
                var documentType = vm.details.availableDocumentTypes;

                var folder = vm.allFolders.find(function (f) {
                    return f.path === vm.details.selectedFolder;
                });

                if (documentType) { // Ensure documentType is found
                    // Prepare data to send to the backend
                    var document = {
                        language: language,
                        filetype: documentType,
                        folder: folder ? vm.details.selectedFolder : '', // Send only the folder path
                        fn: vm.details.title
                    };

                    $http.post("~/api/v1/filestorage/create-document-from-template", document, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (response) {
                            $mdDialog.hide(true); // Close dialog and indicate success
                        })
                        .catch(function (error) {
                            console.error(error);
                            $mdDialog.hide(false); // Close dialog and indicate failure
                        });
                } else {
                    console.error("Document type not found");
                    vm.isValid = false;
                }
            }
        }


        function cancel() {
            $mdDialog.hide();
        }
    }
})();
